import type { Logger } from '../../logger'
import { transformFromRecordToView } from '../transformData'
import {
  evaluateExpression,
  getFormulaJs,
} from '@wix/wix-data-client-common-standalone'

export const transformFromExpressionToView = ({
  expression,
  record,
  propPath,
  role,
  componentIsInput,
  formatter,
  logger,
}: {
  expression: string
  record: Record<any, any>
  propPath: string
  role: string
  componentIsInput?: boolean
  formatter?: any
  logger: Logger
}): string => {
  try {
    const expressionValue = evaluateExpression({
      expression,
      variables: record,
      functions: getFormulaJs()!,
    })
    return transformFromRecordToView({
      value: expressionValue,
      role,
      componentIsInput,
      propPath,
      utils: {
        formatter,
      },
    })
  } catch (err) {
    logger.log(`Failed to evaluate expression: ${err}`)
    return ''
  }
}
